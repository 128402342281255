import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { getDocument, getLocalStorage } from "../../gatsbySafe";
import { GTAG_ID } from "../../config";

export const saveFeedbackGiven = () => {
	const { pathname } = window.location;
	const articleSlug = pathname.split("/").filter(x => !!x)[1];
	getLocalStorage().setItem(`articleFeedback:${articleSlug}`, "true");
};

export const wasFeedbackGiven = () => {
	if (typeof window === undefined) {
		return false;
	}

	const { pathname } = window.location;
	const articleSlug = pathname.split("/").filter(x => !!x)[1];

	return (
		getLocalStorage().getItem(`articleFeedback:${articleSlug}`) === "true"
	);
};

const Feedback = () => {
	const [feedbackGiven, setFeedbackGiven] = useState(wasFeedbackGiven());

	const isClientSide = typeof window !== "undefined";
	useEffect(() => {
		if (isClientSide && !feedbackGiven) {
			setFeedbackGiven(wasFeedbackGiven());
		}
	}, [isClientSide]);

	const sendFeedback = feedback => {
		const { pathname } = window.location;
		const articleSlug = pathname.split("/").filter(x => !!x)[1];

		// Requires google tag manager, load it without analytics
		if (!window.gtag) {
			// Load GTAG
			var gtagScript = getDocument().createElement("script");
			gtagScript.setAttribute(
				"src",
				"https://www.googletagmanager.com/gtag/js?id=" + GTAG_ID
			);
			getDocument().querySelector("body").appendChild(gtagScript);

			// Configure it but with only session cookies, and don't add consent
			// so it won't load next time
			window.dataLayer = window.dataLayer || [];
			window.gtag = function () {
				window.dataLayer.push(arguments);
			};
			window.gtag("js", new Date());
			window.gtag("config", GTAG_ID, {
				cookie_expires: 0, // Session cookie only so it gets deleted when browser closes
			});
		}

		window.gtag("event", "feedback", {
			event_category: "feedback",
			event_label: "article:" + articleSlug,
			value: feedback,
		});

		saveFeedbackGiven();
		setFeedbackGiven(true);
	};

	if (feedbackGiven) {
		return (
			<div className="mt-4 mb-4 border-b border-gray-600 bg-gray-100 dark:bg-gray-700">
				<div
					className={classNames({
						"container mx-auto px-6 py-4 flex items-center": true,
					})}
				>
					<div className="flex-grow">
						<h2 className="text-xl text-teal-700 dark:text-teal-400 tracking-wide">
							Thank you!
						</h2>
						<div className="text-sm text-gray-600 dark:text-gray-200">
							Thanks for sending your feedback. It really helps me
							write better articles for you in future.
						</div>
					</div>
				</div>
			</div>
		);
	}

	const buttonClasses = "border border-gray-500 px-4 py-2";
	return (
		<div className="mt-4 mb-4 border-b border-gray-600 bg-gray-100 dark:bg-gray-700">
			<div
				className={classNames({
					"container mx-auto px-6 py-4 flex items-center": true,
				})}
			>
				<div className="flex-grow">
					<h2 className="text-xl text-teal-700 dark:text-teal-400 tracking-wide">
						Was this page useful for you?
					</h2>
					<div className="text-sm text-gray-600 dark:text-gray-200">
						Please leave feedback. It helps me write better articles
						for you in future.
					</div>
				</div>
				<button
					className={buttonClasses + " mr-4"}
					onClick={() => sendFeedback("positive")}
				>
					Yes
				</button>
				<button
					className={buttonClasses}
					onClick={() => sendFeedback("negative")}
				>
					No
				</button>
			</div>
		</div>
	);
};

export default Feedback;
