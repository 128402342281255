import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import ThemeContext from "../contexts/ThemeContext";
import CookieBanner from "./CookieBanner";

import "./header.css";

const headerLinks = [
	{ caption: "Home", to: "/" },
	{ caption: "Articles", to: "/articles" },
	{ caption: "Start Here", to: "/start-here" },
];

const Header = () => {
	const data = useStaticQuery(graphql`
		query HeaderQuery {
			site {
				siteMetadata {
					title
					description
				}
			}
		}
	`);
	const { title, description } = data.site.siteMetadata;

	return (
		<header className="border-b border-black dark:border-gray-400">
			<CookieBanner />
			<div className="container mx-auto px-6">
				<nav className="flex">
					<div className="flex-grow-0">
						<Link className="block py-2" to="/">
							<h2 className="text-xl text-teal-800 dark:text-teal-400 p-0 m-0">
								{title}
							</h2>
							<span className="text-gray-500 dark:text-gray-300 text-sm hidden md:block">
								{description}
							</span>
						</Link>
					</div>
					<MenuLinks className="hidden md:flex" />
					<div className="flex md:hidden flex-grow justify-end items-center">
						<label htmlFor="openMenu">
							<MenuSvg className="w-6 h-6" />
							<input
								id="openMenu"
								type="checkbox"
								className="hidden"
							/>
							<div className="menuOverlay"></div>
							<div className="menu">
								<MenuLinks />
							</div>
						</label>
					</div>
				</nav>
			</div>
		</header>
	);
};

const MenuLinks = ({ className = "" }) => (
	<ThemeContext.Consumer>
		{theme => (
			<ul className={`flex-grow list-none justify-end ${className}`}>
				{headerLinks.map(({ caption, to }) => (
					<li
						key={to}
						className="px-2 py-2 last-of-type:pr-0 self-center"
					>
						<Link to={to}>{caption}</Link>
					</li>
				))}
				<li className="px-2 py-2 last-of-type:pr-0 self-center hideIfNoJs">
					<button onClick={() => theme.toggleDark()}>
						{theme.dark ? "Light theme" : "Dark theme"}
					</button>
				</li>
			</ul>
		)}
	</ThemeContext.Consumer>
);

const MenuSvg = ({ className = "" }) => (
	<svg
		fill="none"
		viewBox="0 0 24 24"
		stroke="currentColor"
		id="menu"
		className={className}
	>
		<path
			strokeLinecap="round"
			strokeLinejoin="round"
			strokeWidth="2"
			d="M4 6h16M4 12h16M4 18h16"
		></path>
	</svg>
);

export default Header;
