/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ThemeContext from "../contexts/ThemeContext";

const SEO = ({ description = "", meta = [], title, summary = "" }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						description
						author
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	const defaultTitle = site.siteMetadata?.title;

	return (
		<ThemeContext.Consumer>
			{theme => (
				<Helmet
					htmlAttributes={{
						lang: "en",
						class: theme.dark ? "dark" : "",
					}}
					bodyAttributes={{
						class: "dark:bg-gray-800 dark:text-gray-100",
					}}
					title={title}
					titleTemplate={defaultTitle ? `%s • ${defaultTitle}` : null}
					meta={[
						{
							name: `description`,
							content: summary || metaDescription,
						},
						{
							property: `og:title`,
							content: title,
						},
						{
							property: `og:description`,
							content: summary || metaDescription,
						},
						{
							property: `og:type`,
							content: `website`,
						},
						{
							name: `twitter:card`,
							content: summary || metaDescription,
						},
						{
							name: `twitter:creator`,
							content: site.siteMetadata?.author || ``,
						},
						{
							name: `twitter:title`,
							content: title,
						},
						{
							name: `twitter:description`,
							content: metaDescription,
						},
					].concat(meta)}
				></Helmet>
			)}
		</ThemeContext.Consumer>
	);
};

export default SEO;
