import { Link } from "gatsby";
import React, { useContext } from "react";
import CookieConsentContext from "../contexts/CookieConsentContext";

const CookieBanner = () => {
	const { setGaEnabled, consentSaved, saveConsent } = useContext(
		CookieConsentContext
	);

	const pathname =
		typeof window !== "undefined" ? window.location.pathname : "/cookies";

	if (consentSaved || pathname === "/cookies") {
		return null;
	}

	return (
		<section className="py-4 border-b border-black bg-teal-800 text-gray-100 text-sm">
			<div className="container mx-auto px-6">
				<h2 className="font-bold">This website uses cookies</h2>
				<p className="mt-2">
					Some of these cookies enable basic site functionality.
					Others help me understand how the site is used. All
					analytics data is anonymous and cannot be used to identify
					you.
				</p>
				<button
					className="mt-4 mr-2 px-4 py-2 border border-gray-100 bg-gray-100 text-gray-900 rounded"
					onClick={() => {
						setGaEnabled(true);
						saveConsent();
					}}
				>
					Accept and dismiss this message
				</button>
				<Link
					to="/cookies"
					className="inline-block mt-4 px-4 py-2 border border-gray-300 text-gray-200 rounded"
				>
					Customise preferences
				</Link>
			</div>
		</section>
	);
};

export default CookieBanner;
