import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import { getDocument } from "../gatsbySafe";

const Layout = ({ children, padMain = true }) => {
	React.useEffect(() => {
		// The .hideIfNoJs class is added to components that require JS to function
		// It hides things by default, we then immediately remove it from everything
		// via JS so that it only appears when JS is enabled
		[...getDocument().querySelectorAll(".hideIfNoJs")].forEach(x =>
			x.classList.remove("hideIfNoJs")
		);
	}, []);

	return (
		<>
			<Header />
			<main className={padMain ? "pt-4 pb-4" : ""}>{children}</main>
			<Footer />
		</>
	);
};

export default Layout;
