import React, { useMemo } from "react";
import { Link } from "gatsby";
import Feedback from "./UI/Feedback";

const Footer = () => {
	const isClientSide = typeof window !== "undefined";
	const includeFeedback = useMemo(() => {
		if (!isClientSide) {
			return false;
		}

		const isArticle = window.location.pathname.startsWith("/article/");
		return isArticle;
	}, [isClientSide]);

	let footerClasses = "pt-6 pb-6";
	if (!includeFeedback) {
		footerClasses += " border-t border-gray-600";
	}

	return (
		<footer className={footerClasses}>
			{includeFeedback ? <Feedback /> : null}
			<div className="container mx-auto px-6">
				<div>© {new Date().getFullYear()} learnthemeta.com</div>
				<div className="mt-2">
					<Link to="/cookies" className="link">
						Cookies
					</Link>{" "}
					|{" "}
					<Link to="/article/standard-disclaimer" className="link">
						Disclaimer
					</Link>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
